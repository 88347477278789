// extracted by mini-css-extract-plugin
export const container = "index-module--container--k7ALA";
export const navigation = "index-module--navigation--2wkxF";
export const h1 = "index-module--h1--vaVG6";
export const effect = "index-module--effect--AWuXF";
export const small = "index-module--small--bK2D5";
export const effectWrapper = "index-module--effect-wrapper--2xIbc";
export const effect1 = "index-module--effect-1--3FPIS";
export const fadeInUp = "index-module--fade-in-up--8rOEt";
export const backgroundBars = "index-module--background-bars--wC6Wa";
export const spin = "index-module--spin--1Veqz";